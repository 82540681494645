import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultAttributeValuesData = {
  id: 0,
  enabled: true,
  name: '',
  quantity: 1,
  attributeId: '',
  attribute: {
    id: null
  }
};
export var getAttributesValues = function getAttributesValues(params) {
  return request({
    url: '/attributes-values',
    method: 'get',
    params: params
  });
};
export var getAttributesValuesById = function getAttributesValuesById(id) {
  return request({
    url: "/attributes-values/".concat(id),
    method: 'get'
  });
};
export var updateAttributesValues = function updateAttributesValues(id, data) {
  return request({
    url: "/attributes-values/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteAttributesValues = function deleteAttributesValues(id) {
  return request({
    url: "/attributes-values/".concat(id),
    method: 'delete'
  });
};
export var createAttributesValues = function createAttributesValues(data) {
  return request({
    url: '/attributes-values/',
    method: 'post',
    data: data
  });
};